enum AccessType {
  requestTickets = 1,
  useCultivistCard = 2,
  freeEntry = 3,
  purchaseTickets = 4,
}

interface AccessTypeChecker {
  accessByCard: boolean;
  accessByTickets: boolean;
  accessFree: boolean;
}

export function checkAccessType(id?: AccessType) {
  const result: AccessTypeChecker = {
    accessByCard: false,
    accessByTickets: false,
    accessFree: false,
  };

  switch (id) {
    case AccessType.requestTickets:
    case AccessType.purchaseTickets:
      result.accessByTickets = true;
      break;
    case AccessType.useCultivistCard:
      result.accessByCard = true;
      break;
    case AccessType.freeEntry:
      result.accessFree = true;
      break;
  }

  return result;
}
