<template>
  <div class="app-modal-bg" @click.self="close">
    <div class="app-modal" :style="{ maxWidth: `${maxWidth}px` }">
      <button class="modal-close" @click="close">
        <TheCrossIcon />
      </button>

      <div class="app-modal__content">
        <div class="app-modal__text">
          <h3 class="app-modal__header">{{ header }}</h3>
          <div class="app-modal__message">
            <slot>Are you sure?</slot>
          </div>
        </div>
        <div class="d-flex">
          <button class="app-modal__btn btn border-btn" @click="close">{{ labelNo }}</button>
          <button
            class="app-modal__btn btn border-btn"
            @click="
              answer = true;
              close();
            "
          >
            {{ labelYes }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'AppConfirmationModal',
  components: { TheCrossIcon },
  props: {
    header: {
      type: String,
      default: 'Delete',
    },
    labelYes: {
      type: String,
      default: 'Yes',
    },
    labelNo: {
      type: String,
      default: 'No',
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      answer: false,
    };
  },
  methods: {
    close() {
      this.$emit('close', this.answer);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(#000, 0.5);

  @media (max-width: 768px) {
    padding-top: 60px;
  }
}

.app-modal {
  width: calc(100% - 32px);
  padding: 50px;
  position: relative;
  background-color: #fff;

  @media (min-width: 577px) {
    min-width: 520px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    margin: 0 auto 50px;
    color: #353535;

    @media (max-width: 576px) {
      margin-bottom: 40px;
    }
  }

  &__header {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  &__message {
    margin: 36px 0 0;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__btn {
    margin: 0 24px;
    padding: 12px 36px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;

    @media (max-width: 576px) {
      padding: 10px 24px;
      font-size: 14px;
    }
  }
}
</style>
